import {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction
} from "react";
import * as Dapp from "@elrondnetwork/dapp";

export type esdtReturnType = {
  attributes?: string;
  balance: string;
  creator?: string;
  hash?: string;
  name?: string;
  nonce?: number;
  royalties?: string;
  tokenIdentifier: string;
  uris?: string[];
  price?: number;
  denominatedPrice?: number;
};
interface iContext {
  selectedToken: esdtReturnType;
  setSelectedToken: Dispatch<SetStateAction<esdtReturnType>>;
}

export const BalanceContext = createContext<iContext>({} as iContext);

const BalanceProvider = (props: any) => {
  const [selectedToken, setSelectedToken] = useState<esdtReturnType>({
    balance: "0",
    tokenIdentifier: "EGLD"
  } as esdtReturnType);
  return (
    <BalanceContext.Provider value={{ selectedToken, setSelectedToken }}>
      {props.children}
    </BalanceContext.Provider>
  );
};

export const useBalance = () => {
  const { selectedToken, setSelectedToken } = useContext(BalanceContext);
  return { selectedToken, setSelectedToken };
};

export default BalanceProvider;
