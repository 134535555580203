export const decodeToUTF8 = (encoded: string) => {
  return Buffer.from(encoded, "base64").toString("UTF-8");
};

export const decodeToHex = (encoded: string) => {
  return Buffer.from(encoded, "base64").toString("hex");
};

export const decodeEncoded = (encoded: string): Buffer => {
  return Buffer.from(encoded, "base64");
};
