import { createGlobalStyle } from "styled-components";

export const shadow1 = "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)";

export const shadow2 = "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)";

export const shadow3 =
  "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)";

export const shadows = {
  hard:
    "rgba(0, 0, 0, 0.2) 0px 2px 4px, rgba(0, 0, 0, 0.27) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
  semiHard:
    "rgba(209, 222, 230,0.9) 0px 1px 2px, rgba(209, 222, 230,0.8) 0px 8px 13px -3px, rgba(209, 222, 230, 0.2) 0px -3px 0px inset",
  semiHardActive:
    "rgba(209, 222, 230,0.9) 0px 2px 2px, rgba(209, 222, 230,0.8) 0px 2px 2px 0px, rgba(209, 222, 230, 0.2) 0px 0px 0px inset"
};
// Spacing
export const padding = {
  p1: "8px",
  p2: "12px",
  p3: "16px"
};

export const color = {
  background: "#150050",
  backgroundBright: "#1E3163",
  font: "white",
  fontDark: "#121212",
  fontWashed: "rgb(105, 105, 105)",
  fontOpacity6: "rgb(255,255,255,.6)",
  fontSuperLight: "rgb(0,0,0,.1)",
  navBar: "rgba(127, 165, 190,0.3)",
  cardBackground: "#F8F8F8",
  border: "#0F4C75",
  button: "#3282B8",
  primary: "#F8F8F8"
};

export const fontSize = {
  action: "24px",
  semiAction: "16px",
  desktop: "18px",
  header: "26px",
  mobile: "14px"
};

export const dimensions = {
  navBar: 40,
  navItems: 35,
  cardHeight: 250,
  cardWidth: 700,
  cardWidth2: 800,
  cardWidth3: 900
};
export const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
background-color: ${color.backgroundBright};
    color: ${color.fontDark};
    overflow-x: hidden;
    height:100%;
}
html {
  /* overflow-x: hidden; */
  /* overflow-x: hidden; */
  padding: 0;
  margin: 0;
  border: 0;
  z-index:-10;
  background-color: transparent;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

`;
