import {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction
} from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { esdtReturnType } from "./balanceProvider";

interface iContext {
  tokens: esdtReturnType[];
  setTokens: Dispatch<SetStateAction<esdtReturnType[]>>;
}

export const BalancesContext = createContext<iContext>({} as iContext);

const BalancesProvider = (props: any) => {
  const [tokens, setTokens] = useState<esdtReturnType[]>([
    {
      balance: "0",
      tokenIdentifier: "EGLD"
    }
  ]);
  return (
    <BalancesContext.Provider value={{ tokens, setTokens }}>
      {props.children}
    </BalancesContext.Provider>
  );
};

export const useBalances = () => {
  const { tokens, setTokens } = useContext(BalancesContext);
  return { tokens, setTokens };
};

export default BalancesProvider;
