import Back from "components/Back";
import React from "react";
import { color, dimensions, fontSize, padding, shadows } from "style.css";
import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  width: 100%;
  height: 90vh;
`;

export const GridContainer = styled(Container)`
  display: grid;
  align-items: center;
  height: 90vh;
`;
export const Spacer = styled.div`
  height: ${dimensions.navBar + 15}px;
`;

export const GridContainerHeight = styled(GridContainer)`
  height: ${({ h }: { h: string }) => h};
  width: auto;
  max-width: 100vw;
`;
export const CardStyle = styled.div`
  border-radius: 15px;
  padding: ${padding.p3};
  box-shadow: ${shadows.hard};
  background-color: ${color.cardBackground};
  /* margin: ${padding.p3}; */
  text-align: center;
  width: fit-content;
  width: 100%;
  max-width: ${dimensions.cardWidth}px;
  justify-self: center;
  font-size: ${fontSize.semiAction};
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
    width: 100%;
    max-width: ${dimensions.cardWidth}px;
  }
  @media only screen and (min-width: 1200px) {

    max-width: ${dimensions.cardWidth2}px;
  }
  @media only screen and (min-width: 1400px) {

    max-width: ${dimensions.cardWidth3}px;
  }
`;

export const Card = ({
  children,
  path
}: {
  children: React.ReactNode;
  path: string;
}) => {
  return (
    <CardStyle>
      {children}
      <Back path={path} />
    </CardStyle>
  );
};
