import * as React from "react";
import { Link } from "react-router-dom";
import { contractAddress, dAppName } from "../../config";
import { routeNames } from "../../routes";
import styled, { keyframes } from "styled-components";
import { color, dimensions, fontSize, padding, shadows } from "style.css";
import { GridContainerHeight } from "components/StyledComponents";
import * as Dapp from "@elrondnetwork/dapp";
import gif from "../../assets/img/ClawAnimatedHQ.gif";

import {
  Address,
  ContractFunction,
  Query,
  TypedValue,
  decodeUnsignedNumber
} from "@elrondnetwork/erdjs";
import { decodeEncoded } from "pages/Dashboard/helpers/decodeResponse";

const Home = () => {
  const { dapp } = Dapp.useContext();
  const [status, setStatus] = React.useState<number>(0);
  const [timerString, setTimerString] = React.useState<string>();
  const [timerDone, setTimerDone] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<number>(0);
  const [ended, setEnded] = React.useState<boolean>(false);
  const fetchAndUpdate = () => {
    const query = buildQuery(new ContractFunction("getStatus"), []);
    dapp.proxy
      .queryContract(query)
      .then(({ returnData }) => {
        // console.log(returnData);
        const [encoded] = returnData;
        const decoded = decodeUnsignedNumber(decodeEncoded(encoded));
        // console.log(decoded);
        if (decoded === 0) {
          setEnded(true);
          setStatus(3);
        } else {
          setStatus(0);
        }
      })
      .catch(e => {
        console.log("vm response was too slow 1", e);
      });
  };
  const fetchTime = () => {
    const query = buildQuery(new ContractFunction("getStartTime"), []);
    dapp.proxy
      .queryContract(query)
      .then(({ returnData }) => {
        const [encoded] = returnData;
        const decoded = decodeUnsignedNumber(decodeEncoded(encoded));
        setDate(decoded * 1000);
      })
      .catch(e => {
        console.log("vm response was too slow 2", e);
      });
  };

  React.useEffect(() => {
    if (date !== 0) {
      fetchAndUpdate();
    } else {
      fetchTime();
    }
    const interval = setInterval(updateTimer, 1000);
    // const interval2 = setInterval(fetchAndUpdate, 3000);
    return () => {
      clearInterval(interval);
      // clearInterval(interval2);
    };
  }, [date, ended]);

  const updateTimer = () => {
    const now = new Date().getTime(); //UTC
    const dist = date - now;
    if (date !== 0) {
      if (dist < 0) {
        if (status === 1 || status === 0) {
          if (ended) {
            setStatus(3);
          } else {
            setStatus(2);
            setTimerString("waiting for smart contract");
          }
        } else {
          setStatus(3);
          // setStatus(2); //testing
        }
      } else {
        setStatus(1);
        if (timerDone) setTimerDone(false);
        const days = Math.floor(dist / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((dist % (1000 * 60)) / 1000);

        setTimerString(
          dateString(days, true) +
            dateString(hours, true) +
            dateString(minutes, true) +
            dateString(seconds, false)
        );
      }
    }
  };
  const dateString = (s: number, addColon: boolean) => {
    if (s > 0) {
      if (addColon) {
        return `${s < 10 ? `0${s}` : s}:`;
      } else {
        return `${s < 10 ? `0${s}` : s}`;
      }
    } else {
      if (addColon) {
        return "00:";
      } else {
        return "00";
      }
    }
  };
  const buildQuery = (
    func: ContractFunction,
    args: TypedValue[] | undefined
  ): Query => {
    const query = new Query({
      address: new Address(contractAddress),
      func,
      args
    });
    return query;
  };

  const contentRender = () => {
    switch (status) {
      case 0:
        return (
          <Content style={{ fontSize: fontSize.header }}>Loading ...</Content>
        );
      case 1:
        return (
          <>
            <Content>minting starts in:</Content>
            <Content style={{ fontSize: fontSize.header }}>
              {timerString}
            </Content>
          </>
        );
      case 2:
        return (
          <>
            <Content>login to your wallet to continue</Content>
            <LinkButton to={routeNames.unlock} data-testid="loginBtn">
              Login
            </LinkButton>
          </>
        );
      case 3:
        return (
          <Content style={{ fontSize: fontSize.header }}>
            minting has been postponed
          </Content>
        );
    }
  };

  return (
    <GridContainerHeight h={"80vh"}>
      <Margin>
        <CardContainer>
          <GIFContainer>
            <GIF src={gif} />
          </GIFContainer>
          <Card>
            <Title>{dAppName}</Title>
            <Content style={{ fontSize: fontSize.header }}>
              Minting has ended.
            </Content>
          </Card>
        </CardContainer>
      </Margin>
    </GridContainerHeight>
  );
};

export default Home;
const Margin = styled.div`
  justify-self: center;
  margin: ${padding.p3};
  width: 100%;
  padding: ${padding.p3};
  display: grid;
`;
const CardContainer = styled.div`
  background-color: ${color.cardBackground};
  border-radius: 15px;
  display: grid;
  height: ${dimensions.cardHeight}px;
  max-width: ${dimensions.cardWidth}px;
  box-shadow: ${shadows.hard};
  justify-self: center;
  width: 100%;
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
    width: 100%;
    max-width: ${dimensions.cardWidth}px;
  }
`;

const Card = styled.div`
  display: grid;
  justify-items: center;
  margin: ${padding.p3};
  row-gap: ${padding.p2};
`;

const Title = styled.h2`
  justify-self: start;
  margin: 0;
  font-weight: normal;
  color: #1b262c;
`;

const Content = styled.div`
  color: #1b262c;
  font-size: ${fontSize.semiAction};
`;

const LinkButton = styled(Link)`
  display: grid;
  text-decoration: none;
  font-weight: bold;
  font-size: ${fontSize.action};
  color: ${color.button};
  box-shadow: ${shadows.semiHard};
  padding: 0 ${padding.p3} 0 ${padding.p3};
  border-radius: 15px;
  &:visited {
    color: ${color.button};
  }
  &:hover {
    cursor: pointer;
  }
  &:active {
    box-shadow: ${shadows.semiHardActive};
  }
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

const LinkButtonDisabled = styled(LinkButton)`
  color: ${color.fontWashed};
  box-shadow: ${shadows.semiHardActive};
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

const anim1 = keyframes`
from {
  transform:translateY(0);
}
to {
  transform: translateY(-130px);
}
`;

const anim2 = keyframes`
from {
  transform:translateY(0);
}
to {
  transform: translateY(-180px);
}
`;

export const GIFContainer = styled.div`
  position: absolute;
  z-index: -1;
  display: grid;
  justify-items: center;
  /* align-items: center; */
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
    width: 100%;
    max-width: ${dimensions.cardWidth}px;
  }
  justify-self: center;
`;

export const GIF = styled.img`
  width: 130px;
  margin-right: 200px;
  animation: ${anim1} 0.15s ease-in-out forwards;
  @media only screen and (min-width: 768px) {
    width: 180px;
    margin-right: 350px;
    animation: ${anim2} 0.15s ease-in-out forwards;
  }
`;
