import * as Dapp from "@elrondnetwork/dapp";

const devnet = false;

export const dAppName = "SuperRareBears Minter";
export const decimals = 2;
export const denomination = 18;
export const gasPrice = 1000000000;
export const version = 1;
export const gasLimit = 50000;
export const gasPerDataByte = 1500;
export const walletConnectBridge = "https://bridge.walletconnect.org";
export const walletConnectDeepLink =
  "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.dev&link=https://maiar.com/";

export const contractAddress = devnet
  ? //  "erd1qqqqqqqqqqqqqpgqagdaurqwz7jkcy740pm9tpcgm5jcvnnep2vql9e0ce"
    // "erd1qqqqqqqqqqqqqpgqv658x3k3y9hcnmf2styxwkr8nf32y5nwp2vq00apey" //devnet (1000)
    // "erd1qqqqqqqqqqqqqpgqxvwxr2sxf3e0ym532ukezkztkqarwsqxp2vqn55j04"
    "erd1qqqqqqqqqqqqqpgqx0hkazhpvdg5l79dvafjqe3vqfle2ujhp2vqj76y3f"
  : "erd1qqqqqqqqqqqqqpgqf6qde2f9dt8u943la9ehgvemzldx7rccxjhs3nqucu";
export const network: Dapp.NetworkType = devnet
  ? {
      id: "devnet",
      name: "Devnet",
      egldLabel: "xEGLD",
      walletAddress: "https://devnet-wallet.elrond.com",
      apiAddress: "https://devnet-api.elrond.com",
      gatewayAddress: "https://devnet-gateway.elrond.com",
      explorerAddress: "http://devnet-explorer.elrond.com/"
    }
  : {
      id: "mainnet",
      name: "Mainnet",
      egldLabel: "eGLD",
      walletAddress: "https://wallet.elrond.com/",
      apiAddress: "https://api.elrond.com",
      gatewayAddress: "https://gateway.elrond.com",
      explorerAddress: "https://explorer.elrond.com/"
    };
export const gatewayAddress = devnet
  ? "https://devnet-gateway.elrond.com"
  : "https://gateway.elrond.com";
export const apiAddress = devnet
  ? "https://devnet-api.elrond.com"
  : "https://api.elrond.com";
export const tokenDenomination = 1;
export const egldDenomination = 18;
export const tokenId = devnet ? "PARE-ce8a07" : "RARE-3d5280";
// "OITYT-3d8ac5";
// "BDHM-b186f7";
export const paymentTokenId = devnet ? "LKMEXICO-760044" : "";
export const paymentTokenId2 = devnet ? "PARE-ce8a07" : "RARE-3d5280";

export const nftTokenId = devnet ? "DLP-a03e87" : "SRB-61daf7";

//++============================================================================================
// import * as Dapp from "@elrondnetwork/dapp";

// export const dAppName = "$RARE Presale";
// export const decimals = 2;
// export const denomination = 18;
// export const gasPrice = 1000000000;
// export const version = 1;
// export const gasLimit = 50000;
// export const gasPerDataByte = 1500;

// export const walletConnectBridge = "https://bridge.walletconnect.org";
// export const walletConnectDeepLink =
//   "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.dev&link=https://maiar.com/";

// export const contractAddress =
//   // "erd1qqqqqqqqqqqqqpgqjfprdqrwuf8pa4rlperc6f9xel7m0czmp2vqadzdpt" // PARE DEVNET;
//   "erd1qqqqqqqqqqqqqpgqdgmhxayl29hkvqwkf9na7d90d3n2p0eep2vqxed3an"; // v2 LKSOL PARE
// // "erd1qqqqqqqqqqqqqpgq799xus40hpsh4c0nfvpjqftq6mwq09gep2vqrxcsae"; // giveaway
// export const tokenId = "PARE-ce8a07";
// export const network: Dapp.NetworkType = {
//   id: "devnet",
//   name: "Devnet",
//   egldLabel: "xEGLD",
//   walletAddress: "https://devnet-wallet.elrond.com",
//   apiAddress: "https://devnet-api.elrond.com",
//   gatewayAddress: "https://devnet-gateway.elrond.com",
//   explorerAddress: "http://devnet-explorer.elrond.com/"
// };
// export const gatewayAddress = "https://devnet-gateway.elrond.com";
// export const apiAddress = "https://devnet-api.elrond.com";
// export const tokenDenomination = 1;
// export const egldDenomination = 18;
// export const paymentTokenId = "LKMEXICO-760044";
// export const paymentTokenId2 = "MEX-6969ab";
