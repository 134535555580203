import { GridContainer } from "components/StyledComponents";
import { useLocation } from "react-router-dom";
import { color, padding } from "style.css";
import styled from "styled-components";
import { ReactComponent as HeartIcon } from "../../../assets/img/heart.svg";

const Footer = () => {
  const location = useLocation();

  return (
    <SizedGridContainer>
      {location.pathname === "/transaction" ? null : (
        <>
          <Link
            {...{
              target: "_blank"
            }}
            className="d-flex align-items-center"
            href="https://superrarebears.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Made with <RedHeartIcon /> by SuperRareBears.
          </Link>
        </>
      )}
    </SizedGridContainer>
  );
};

export default Footer;

const SizedGridContainer = styled(GridContainer)`
  height: fit-content;
  padding: ${padding.p3} 0 ${padding.p3} 0;
  justify-items: center;
`;

const Link = styled.a`
  color: ${color.fontWashed};
`;

const RedHeartIcon = styled(HeartIcon)`
  fill: red;
`;
