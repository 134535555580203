import { usePresaleStatus } from "context/presaleStatusProvider";
import { useEffect } from "react";
import { useHistory } from "react-router";

const WithRedirect = () => {
  const history = useHistory();
  const { presaleStatus } = usePresaleStatus();

  useEffect(() => {
    if (!presaleStatus) {
      history.push("/");
    }
  }, [presaleStatus]);
  return <></>;
};

export default WithRedirect;
