import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { color, dimensions, fontSize, padding, shadow1 } from "style.css";
import logo from "./../../../assets/img/Bears_white_logo.png";
const Navbar = () => {
  const { loggedIn, address } = Dapp.useContext();
  const dappLogout = Dapp.useLogout();
  const history = useHistory();
  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/` });
    history.push("/");
  };

  return (
    <NavbarContainer>
      <NavContainer>
        <LinkButton to={loggedIn ? "/dashboard" : "/"}>
          <Logo src={logo} />
        </LinkButton>
        <Nav>
          <AddressContainer>
            {address
              ? address.slice(0, 5) + "..." + address.slice(-5, address.length)
              : null}
          </AddressContainer>
          {loggedIn && (
            <NavItem>
              <a
                href="/"
                onClick={logOut}
                style={{ fontWeight: "bold", color: color.cardBackground }}
              >
                logout
              </a>
            </NavItem>
          )}
        </Nav>
      </NavContainer>
    </NavbarContainer>
  );
};

export default Navbar;

const NavbarContainer = styled.div`
  position: fixed;
  top: 0;
  max-height: ${dimensions.navBar}px;
  width: 100vw;
  z-index: 20;
`;

const NavContainer = styled.div`
  padding: ${padding.p1};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  background-color: ${color.navBar};
  box-shadow: ${shadow1};
  column-gap: ${padding.p3};
`;

const LinkButton = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  font-size: ${fontSize.action};

  width: fit-content;
  &:visited {
    color: inherit;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  height: ${dimensions.navItems}px;
`;

const AddressContainer = styled.div`
  display: grid;
  align-items: center;
  justify-self: end;
  color: ${color.cardBackground};
`;

const Nav = styled.div`
  display: grid;
  justify-self: end;
  align-items: center;
  padding-right: ${padding.p3};
`;

const NavItem = styled.div`
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.semiAction};
  }
`;
