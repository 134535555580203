import {
  createContext,
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction
} from "react";
import * as Dapp from "@elrondnetwork/dapp";
import {
  Address,
  ContractFunction,
  Query,
  TypedValue,
  decodeUnsignedNumber
} from "@elrondnetwork/erdjs";
import { decodeEncoded } from "pages/Dashboard/helpers/decodeResponse";

import { contractAddress } from "config";
import { useLocation } from "react-router";

interface iContext {
  presaleStatus: boolean;
  setPresaleStatus: Dispatch<SetStateAction<boolean>>;
}

export const PresaleStatusContext = createContext<iContext>({} as iContext);

const PresaleStatusProvider = (props: any) => {
  const { dapp } = Dapp.useContext();
  const [presaleStatus, setPresaleStatus] = useState<boolean>(true);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/") {
      fetchTime();
    }
  }, []);

  const fetchTime = () => {
    const query = buildQuery(new ContractFunction("getStartTime"), []);
    dapp.proxy
      .queryContract(query)
      .then(({ returnData }) => {
        const [encoded] = returnData;
        const decoded = decodeUnsignedNumber(decodeEncoded(encoded));
        if (new Date().getTime() < decoded * 1000) {
          setPresaleStatus(false);
        }
      })
      .catch(e => {
        console.log("vm response was too slow, getStartTime", e);
      });
    const query2 = buildQuery(new ContractFunction("getStatus"), []);
    dapp.proxy
      .queryContract(query2)
      .then(({ returnData }) => {
        const [encoded] = returnData;
        const decoded = decodeUnsignedNumber(decodeEncoded(encoded));
        if (decoded === 0) {
          setPresaleStatus(false);
        }
      })
      .catch(e => {
        console.log("vm response was too slow, getPresaleStatus", e);
      });
  };

  const buildQuery = (
    func: ContractFunction,
    args: TypedValue[] | undefined
  ): Query => {
    const query = new Query({
      address: new Address(contractAddress),
      func,
      args
    });
    return query;
  };

  return (
    <PresaleStatusContext.Provider value={{ presaleStatus, setPresaleStatus }}>
      {props.children}
    </PresaleStatusContext.Provider>
  );
};

export const usePresaleStatus = () => {
  const { presaleStatus, setPresaleStatus } = useContext(PresaleStatusContext);
  return { presaleStatus, setPresaleStatus };
};

export default PresaleStatusProvider;
