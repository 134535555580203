import { useHistory } from "react-router";
import { color, fontSize } from "style.css";
import styled from "styled-components";

const Back = ({ path }: { path: string }) => {
  const history = useHistory();

  return <Text onClick={() => history.push(path)}>back</Text>;
};

const Text = styled.button`
  font-size: ${fontSize.semiAction};
  border: none;
  outline: none;
  text-decoration: underline;
  color: ${color.button};

  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;

  &:hover {
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
    cursor: pointer;
  }

  &:active {
    outline: none;
    border: none;
  }

  &:focus {
    outline: 0;
  }
`;

export default Back;
